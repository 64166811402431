body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}
.order-total {
  padding: 8px;
}

.order-total .sub-total,
.order-total .total {
  display: flex;
  justify-content: space-between;
}

.order-total .sub-total span,
.order-total .total span {
  text-transform: capitalize !important;
}

.nav-bar {
  background-color: #fafafa;
  padding: 8px;
}

.nav-bar a {
  text-decoration: none;
  margin-right: 8px;
  color: #1c222d;
  font-weight: bold;
}

.date-time {
  text-transform: lowercase !important;
}

.address {
  margin-top: 16px;
  margin-bottom: 16px;
}

.App {
  min-height: 100vh;
  background-color: #eceff1;
}

.App #mains button {
  border: none;
  padding: 8px;
  font-size: 16px;
  font-weight: bold;
  background-color: #fafafa;
  width: 128px;
  margin: 8px;
}

.App #mains #current,
.App #mains #archived {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 8px;
  padding: 8px;
}

.App #mains #current .order-sum,
.App #mains #archived .order-sum {
  text-transform: capitalize;
  padding: 8px;
  border-radius: 0.3em;
  color: #263238;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.App #mains #current .order-sum .order-item,
.App #mains #archived .order-sum .order-item {
  padding: 8px;
}

.App #mains #current .order-sum .order-item .item-details,
.App #mains #archived .order-sum .order-item .item-details {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
}

.App #mains #current .order-sum .order-item .item-qty,
.App #mains #archived .order-sum .order-item .item-qty {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.App #mains #current .order-sum .order-item .item-qty h5,
.App #mains #archived .order-sum .order-item .item-qty h5 {
  margin: 4px;
}

.App #mains #current .order-sum .order-item .item-qty .edit-qty,
.App #mains #archived .order-sum .order-item .item-qty .edit-qty {
  background: none;
  border: none;
  color: #000;
}
/*# sourceMappingURL=App.css.map */
